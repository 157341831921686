<template>
  <div class="tree-view--wrap">
    <v-list
      v-for="item in items"
      :key="item.id"
      density="compact"
      class="pa-0"
      :aria-label="getAriaLabelForItem(item)"
    >
      <v-list-group
        v-if="item.children && item.children.length > 0"
        :value="item.id"
        role="group"
      >
        <template #activator="{ isOpen, props }">
          <v-list-item
            v-bind="props"
            append-icon=""
            role="option"
          >
            <v-list-item-title>
              <v-icon
                start
                :icon="isOpen ? '$menu-down' : '$menu-right'"
                aria-hidden="true"
              />

              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
        </template>

        <tree-view
          :items="item.children"
        />
      </v-list-group>

      <v-list-item
        v-else-if="item.path"
        :to="item.path"
        role="option"
      >
        <v-list-item-title class="pl-9">
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: 'tree-view',

  props: {
    items: {
      type: Array,
      default: () => ([]),
    },
  },

  methods: {
    getAriaLabelForItem (item) {
      return item.children && item.children.length > 0
        ? `Seiten in der Rubrik '${item.title}' anzeigen`
        : item.title
    },
  },
}
</script>
