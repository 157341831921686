<template>
  <ClientOnly>
    <v-navigation-drawer
      v-model="showDrawer"
      :width="380"
      floating
      temporary
      class="mobile-navigation"
    >
      <v-sheet :height="100" class="pa-4 d-flex align-center position-relative">
        <cms-logo width="240" class="drawer-logo d-inline-block mr-6" />

        <v-btn
          icon="$close"
          variant="outlined"
          flat
          tile
          class="ml-auto"
          aria-label="Navigation schließen"
          @click="showDrawer = false"
        />
      </v-sheet>

      <v-divider class="mb-4" />

      <tree-view :items="navEntries" />
    </v-navigation-drawer>
  </ClientOnly>
</template>

<script>
import categoryTree from '@/preload/data/categories.json'

export default {
  name: 'mobile-navigation',

  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
  },

  emits: [
    'update:model-value'
  ],

  data () {
    return {
      navEntries: categoryTree,
    }
  },

  computed: {
    showDrawer: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:model-value', value)
      }
    },
  },
}
</script>

<style lang="scss">
  .mobile-navigation {
    // above superchat-plugin and scrim
    z-index: 10006 !important;

    + .v-navigation-drawer__scrim {
      // above superchat-plugin, below drawer-content
      z-index: 10005;
    }

    .drawer-logo {
      max-width: 100%;
    }

    .v-treeview-group {
      &.v-list-group--open {
        margin-bottom: 32px;
      }

      .v-list-group__header {
        .v-list-item-title {
          font-weight: 600;
        }
      }
    }
  }
</style>
