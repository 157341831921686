<script setup>
import { ref } from 'vue'
import { register } from 'swiper/element/bundle'
import config from '@/preload/data/config.json'
import scripts from '@/preload/data/scripts.json'

register()

useHead({
  title: config.pagetitle || '',
  meta: [
    { name: 'description', content: config.description || '' }
  ],
  htmlAttrs: {
    lang: 'de',
  },
  script: (scripts || []).map(script => ({
    src: script.url,
    type: 'text/javascript',
    tagPosition: 'bodyClose',
    defer: true,
  })),
  link: [
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/favicons/apple-touch-icon.png' },
    { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicons/favicon-32x32.png' },
    { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicons/favicon-16x16.png' },
    // { rel: 'manifest', href: '/favicons/site.webmanifest' },
  ]
})

const headerHeight = ref(100)
const showMobileNavigation = ref(false)
</script>

<template>
  <v-app>
    <NuxtRouteAnnouncer />

    <mobile-navigation v-model="showMobileNavigation" />

    <app-bar
      :header-height="headerHeight"
      @show:mobile-navigation="showMobileNavigation = true"
    />

    <contact-badge is-desktop-mode />

    <v-main class="flex-grow-1" :style="{ paddingTop: `${headerHeight}px` }">
      <contact-badge />
      <NuxtPage />
    </v-main>

    <app-footer class="flex-grow-0" />
  </v-app>
</template>

<style lang="scss">
  .v-application__wrap {
    .v-main {
      // padding-top: 100px !important;
      transition: none;
    }
  }
</style>
