<template>
  <v-app-bar
    v-scroll="onScroll"
    :elevation="elevateHeader ? 10 : 0"
    color="white"
    flat
    :height="headerHeight"
    class="app-bar--wrap"
    :class="{
      'border-b': !elevateHeader
    }"
  >
    <v-container>
      <div class="d-flex align-center">
        <NuxtLink to="/" class="mr-10">
          <cms-logo width="340" class="header-logo" />
        </NuxtLink>

        <v-spacer />

        <nav-tabs
          :nav-entries="navEntries"
          :height="headerHeight"
          class="d-none d-lg-block"
        />

        <v-btn
          icon="$menu"
          class="d-lg-none"
          aria-label="Navigation anzeigen"
          @click="$emit('show:mobile-navigation')"
        />

        <theme-switch class="ml-4">
          <img
            src="/img/ico_gib.svg"
            width="40px"
            height="32px"
            alt="GIB-Icon"
          >
        </theme-switch>
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import categoryTree from '@/preload/data/categories.json'

export default {
  name: 'app-bar',

  props: {
    headerHeight: {
      type: Number,
      default: 100,
    },
  },

  emits: [
    'show:mobile-navigation',
  ],

  data () {
    return {
      elevateHeader: false,
      navEntries: [
        ...categoryTree,
      ],
    }
  },

  methods: {
    onScroll (e) {
      this.elevateHeader = e.target.documentElement.scrollTop >= 60
    },
  },
}
</script>

<style lang="scss">
  .app-bar--wrap {
    .header-logo {
      max-height: 64px;
      max-width: 100%;
    }

    @media (min-width: 600px) and (max-width: 1919px) {
      .v-container {
        max-width: 95%;
      }
    }
  }
</style>
