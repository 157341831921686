<template>
  <div class="cms-logo--wrap">
    <cms-image
      :image-data="logo"
      :alt-fallback="name"
      loading="eager"
      :width="width"
      :cover="false"
    />
  </div>
</template>

<script>
import config from '@/preload/data/config.json'

export default {
  name: 'cms-logo',

  props: {
    width: {
      type: [Number, String],
      default: null,
    },
  },

  data () {
    return {
      logo: config.logo,
      name: config.name,
    }
  },
}
</script>
