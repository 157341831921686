<template>
  <div class="nav-tabs--wrap">
    <v-tabs
      :model-value="activeTab"
      :height="height"
      :mandatory="false"
      slider-color="primary"
      @update:model-value="() => {}"
    >
      <v-tab
        v-for="(entry, i) in navEntries"
        :key="i"
        :value="entry.id"
        class="rounded-0"
        tabindex="0"
        :to="entry.children && entry.children.length > 0 ? '' : entry.path"
      >
        {{ entry.title }}

        <v-icon
          v-if="entry.children && entry.children.length > 0"
          icon="$menu-down"
          end
        />

        <v-menu
          v-if="entry.children && entry.children.length > 0"
          activator="parent"
          open-on-hover
          :open-on-click="true"
          :open-on-focus="false"
        >
          <v-list class="pa-0 rounded-0">
            <template v-for="(child, x) in entry.children" :key="`${i}_${x}`">
              <v-list-item
                :to="child.path"
                color="primary"
              >
                <v-list-item-title class="py-4 px-2">
                  {{ child.title }}
                </v-list-item-title>
              </v-list-item>

              <v-divider
                v-if="x < entry.children.length - 1"
              />
            </template>
          </v-list>
        </v-menu>
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  name: 'nav-tabs',

  props: {
    navEntries: {
      type: Array,
      default: () => ([]),
    },

    height: {
      type: Number,
      default: 64,
    },
  },

  data () {
    return {
      activeTab: null,
    }
  },

  watch: {
    '$route.path' (to) {
      this.setActiveTabForPath(to)
    },
  },

  mounted () {
    this.setActiveTabForPath(this.$route.path)
  },

  methods: {
    setActiveTabForPath (path) {
      const tab = this.navEntries.find(entry => {
        const pathMatch = entry.path === path
        const childMatch = entry.children?.length && entry.children.find(child => child.path === path) !== undefined

        return pathMatch || childMatch
      })

      this.activeTab = tab?.id || null
    },
  },
}
</script>

<style lang="scss">
  .nav-tabs--wrap {
    .v-tab__slider {
      // top: 0;
      height: 4px;
    }

    .v-tab {
      text-transform: none;
      font-size: 1rem;

      &.v-tab--selected {
        color: rgb(var(--v-theme-primary));
      }

      &:hover,
      &.v-tab--selected,
      &[aria-haspopup=menu][aria-expanded=true] {
        color: rgb(var(--v-theme-primary));

        .v-btn__overlay {
          opacity: 0.08 !important;
        }
      }
    }
  }
</style>
