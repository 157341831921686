<template>
  <!-- eslint-disable-next-line vue/no-v-html -->
  <span class="cms-html-content--wrap" v-html="html" />
</template>

<script>
/**
 * Important note:
 *
 * The RTE of strapi doesn't persist HTML, only markdown. But this component
 * expects pure html. Since we don't want to transform that stuff on the client
 * (that would add a noticable delay), the transformation must be done while
 * preloading the data.
 *
 * @see preload/index.js
 * @see preload/utils/pages.js
 */
export default {
  name: 'cms-html-content',

  props: {
    html: {
      type: String,
      default: ''
    },
  },
}
</script>
