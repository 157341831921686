<template>
  <div class="theme-switch--wrap d-inline-block cursor-pointer" role="button">
    <slot />

    <v-menu
      activator="parent"
      :close-on-content-click="false"
    >
      <v-list class="pa-0 rounded-0">
        <template v-for="(theme, x) in themes" :key="x">
          <v-list-item @click="setColor(theme.color)">
            <v-list-item-title class="py-4 px-2">
              <div class="d-flex align-center">
                <v-sheet
                  :color="theme.color"
                  width="10"
                  height="10"
                  class="mr-2"
                  rounded="xl"
                />
                {{ theme.name }}
              </div>
            </v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <v-list-item @click="setColor(customColor)">
          <v-list-item-title class="py-4 px-2">
            <div class="d-flex align-center">
              <v-sheet
                :color="customColor"
                width="10"
                height="10"
                class="mr-2"
                rounded="xl"
              />
              Eigene Farbe
            </div>
          </v-list-item-title>
          <v-color-picker
            v-model="customColor"
            mode="rgb"
            hide-inputs
          />
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { themes } from '@/data/meta.js'

export default {
  name: 'theme-switch',

  data () {
    return {
      themes,
      customColor: '#ddd',
    }
  },

  methods: {
    setColor (color) {
      this.$vuetify.theme.themes.light.colors.primary = color
    }
  }
}
</script>
