
import * as ipxRuntime$WZoUCd2JPp from '/var/www/nuxt/node_modules/@nuxt/image/dist/runtime/providers/ipx'
import * as ipxStaticRuntime$XMpuF3YDNU from '/var/www/nuxt/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 600,
    "md": 960,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1600,
    "2xl": 1600
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "vpro1000.proserver.punkt.de"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipx']: { provider: ipxRuntime$WZoUCd2JPp, defaults: {"modifiers":{"format":"webp"}} },
  ['ipxStatic']: { provider: ipxStaticRuntime$XMpuF3YDNU, defaults: {"modifiers":{"format":"webp"}} }
}
        