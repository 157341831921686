<template>
  <div class="app-footer--wrap d-flex flex-column">
    <v-sheet color="primary" class="py-15">
      <v-container>
        <v-row>
          <v-col
            v-if="column1"
            :cols="12"
            :sm="6"
            :lg="3"
            class="pl-8 mb-10 mb-lg-0"
          >
            <div class="text-h5 font-weight-medium mb-4">
              {{ column1.title }}
            </div>

            <cms-html-content :html="column1.text" />
          </v-col>

          <v-col
            v-if="column2"
            :cols="12"
            :sm="6"
            :lg="3"
            class="pl-8 mb-10 mb-lg-0"
          >
            <div class="text-h5 font-weight-medium mb-4">
              {{ column2.title }}
            </div>

            <cms-html-content :html="column2.text" />
          </v-col>

          <v-col
            v-if="column3"
            :cols="12"
            :sm="6"
            :lg="3"
            class="pl-8 mb-10 mb-lg-0"
          >
            <div class="text-h5 font-weight-medium mb-4">
              {{ column3.title }}
            </div>

            <cms-html-content :html="column3.text" />
          </v-col>

          <v-col :cols="12" :sm="6" :lg="3">
            <div
              id="information-heading"
              class="text-h5 font-weight-medium mb-4 pl-4"
            >
              Informationen
            </div>

            <v-list
              bg-color="transparent"
              density="compact"
              class="ma-0 pa-0"
              aria-labelledby="information-heading"
            >
              <v-list-item role="option" to="/datenschutz/">
                <v-list-item-title>
                  Datenschutz
                </v-list-item-title>
              </v-list-item>
              <v-list-item role="option" to="/impressum/">
                <v-list-item-title>
                  Impressum
                </v-list-item-title>
              </v-list-item>
              <v-list-item role="option" to="/sitemap/">
                <v-list-item-title>
                  Sitemap
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <v-sheet
      color="grey-lighten-4"
      class="py-10"
    >
      <v-container>
        <v-row align="center">
          <v-col :cols="12" :md="6">
            <a
              href="http://www.gesundistbunt.de/"
              target="_blank"
              title="Zur Gesund ist bunt Webseite"
              class="gib-claim"
            >
              <v-img
                src="/img/gib_claim.svg"
                alt="Gesund ist bunt - Aus Liebe zu Ihrer Gesundheit."
                width="400"
                height="50"
                contain
                class="mx-auto mx-lg-0"
              />
            </a>
          </v-col>

          <v-col :cols="12" :md="6" class="text-primary text-center text-lg-right mt-4 mt-lg-0">
            <span class="font-weight-medium">
              © {{ currentYear }} {{ config.name }}
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </div>
</template>

<script>
import config from '@/preload/data/config.json'
import footer from '@/preload/data/footer.json'

export default {
  name: 'app-footer',

  data () {
    return {
      config,
    }
  },

  computed: {
    currentYear () {
      return new Date().getFullYear()
    },

    column1 () {
      return this.getColumn('column1')
    },

    column2 () {
      return this.getColumn('column2')
    },

    column3 () {
      return this.getColumn('column3')
    },
  },

  methods: {
    getColumn (key) {
      return Array.isArray(footer)
        ? null // footer has no configuration, empty array is the fallback
        : footer[key]
    },
  }
}
</script>

<style lang="scss">
  .app-footer--wrap {
    .gib-claim {
      display: inline-block;
      opacity: 0.48;
      transition-duration: 0.8s;
      transition-property: opacity;
      transition-timing-function: cubic-bezier(0.190, 1.000, 0.220, 1.000);

      &:hover {
        opacity: 1;
      }
    }
  }
</style>
