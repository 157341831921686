// @todo: evtl. 'runtimeConfig' oder 'defineAppConfig' von nuxt nutzen

export const themes = [
  { color: '#B40025', name: 'Rot' },
  { color: '#3F51B5', name: 'Blau' },
  { color: '#00A94F', name: 'Grün' },
  { color: '#ED7100', name: 'September-Oktober' },
  { color: '#822323', name: 'November-Dezember' },
  { color: '#89AAD5', name: 'Januar-Februar' },
  { color: '#AFCD50', name: 'März-April' },
  { color: '#FFBE0A', name: 'Mai-Juni' },
  { color: '#34969B', name: 'Juli-August' },
]

export const mapConfig = {
  fallbackImage: '/img/fakemap.webp',
  embedLink: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1486.822155621976!2d6.66549874360575!3d51.190943235986275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47b8b4f1c5b4d631%3A0xd39076184e59ade6!2sBehring%20Apotheke!5e0!3m2!1sde!2sde!4v1718800808931!5m2!1sde!2sde',
  active: true,
}

export default {
  name: 'Behring Apotheke',
  title: 'Gesund ist bunt Behring Apotheke, Neuss | Behring Apotheke',
  description: 'Behring Apotheke, Margarita Terjung. 24h-Abholautomat. Wir lösen E-Rezepte ein und sind Expert*innen auf den Gebieten: Cannabis, Impfungen, Tagespflege uvm.',
  theme: themes[0],
  contact: {
    name: 'Apothekerin Margarita Terjung e. K.',
    street: 'Behringstr. 1',
    city: '41464 Neuss',
    phone: '+49 2131 984300',
    fax: '+49 2131 9843020',
    mail: 'post@behring-apotheke.de',
    map: 'https://maps.app.goo.gl/BZHFqc2igwNCYP7V9',
    instagram: 'https://www.instagram.com/behringapotheke/?igsh=MXN4cm1qejZhMm04MA%3D%3D',
    facebook: 'https://www.facebook.com/people/Behring-Apotheke-Neuss/100063824873825/',
    whatsapp: '+492131984300',
    whatsappReadable: '+49 2131 984300',
  },
  shop: 'https://behring-apotheke.ptcloud.de/',
  scripts: [
    'https://widget.superchat.de/snippet.js?applicationKey=WC1XQN40jAd9g6NZr3kv6mYZ8e',
  ]
}
