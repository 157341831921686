<script setup>
import { mdiAt, mdiCartVariant, mdiFacebook, mdiMapMarker, mdiInstagram, mdiWhatsapp, mdiPhone } from '@mdi/js'
import contactData from '@/preload/data/contact.json'
import config from '@/preload/data/config.json'

const { isDesktopMode } = defineProps({ isDesktopMode: Boolean })
const btnSizeMobile = 42
const btnSizeDesktop = 48
const entries = []

if (config && contactData && !Array.isArray(config) && !Array.isArray(contactData)) {
  buildBadgeEntries()
}

function buildBadgeEntries () {
  if (contactData.mail) {
    entries.push({ title: `E-Mail an ${config.name}`, icon: mdiAt, href: `mailto:${contactData.mail}` })
  }

  if (contactData.mapLink) {
    entries.push({ title: `Zum Karteneintrag bei google-maps von ${config.name}`, icon: mdiMapMarker, href: contactData.mapLink })
  }

  if (config.shop) {
    entries.push({ title: `Zum Apotheken-Shop von ${config.name}`, icon: mdiCartVariant, href: config.shop })
  }

  if (contactData.facebook) {
    entries.push({ title: `Zur "facebook"-Seite von ${config.name}`, icon: mdiFacebook, href: contactData.facebook })
  }

  if (contactData.instagram) {
    entries.push({ title: `Zur "Instagram"-Seite von ${config.name}`, icon: mdiInstagram, href: contactData.instagram })
  }

  if (contactData.whatsapp) {
    entries.push({ title: `WhatsApp an ${config.name} senden unter ${contactData.whatsappReadable || contactData.whatsapp}`, icon: mdiWhatsapp, href: `https://wa.me/${contactData.whatsapp.replaceAll(' ', '')}` })
  }

  if (contactData.phone) {
    entries.push({ title: `${config.name} unter ${contactData.phone} Anrufen`, icon: mdiPhone, href: `tel:${contactData.phone.replaceAll(' ', '')}` })
  }
}

</script>

<template>
  <div
    class="contact-badge--wrap"
    :class="{
      'is--desktop d-none d-sm-block': isDesktopMode,
      'd-sm-none': !isDesktopMode,
    }"
  >
    <v-sheet
      color="primary"
      class="d-flex"
      :class="{
        'flex-column elevation-10': isDesktopMode,
      }"
    >
      <div
        v-for="(entry, i) in entries"
        :key="i"
        :class="{
          'flex-grow-1': !isDesktopMode,
        }"
      >
        <v-tooltip
          :text="entry.title"
          :location="isDesktopMode ? 'start' : 'bottom'"
          :aria-label="entry.title"
        >
          <template #activator="{ props }">
            <v-btn
              v-bind="props"
              :width="isDesktopMode ? btnSizeDesktop : '100%'"
              :height="isDesktopMode ? btnSizeDesktop : btnSizeMobile"
              flat
              tile
              color="primary"
              :icon="entry.icon"
              :aria-label="entry.title"
              :href="entry.href"
              target="_blank"
            />
          </template>
        </v-tooltip>
        <v-divider
          v-if="i < entries.length - 1"
          class="mx-1"
        />
      </div>
    </v-sheet>
  </div>
</template>

<style lang="scss">
  .contact-badge--wrap {
    &.is--desktop {
      position: fixed;
      right: 0;
      top: 15vh;
      z-index: 1003; /* 1 above map-overlays, 1 below toolbar/app-header */
      outline: 1px solid rgba(255, 255, 255, 0.12);
    }
  }
</style>
